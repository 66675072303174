import React from "react"
import MathForCsRoll from "../components/MathForCsRoll"
import { Link } from "gatsby"
import NavigationBar from "../components/Navbar"
import Footer from "../components/footer"
import EnjoyMathematicsHeader from "../components/enjoymathheader"
import { Helmet } from "react-helmet"

export default class MathForCSPage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta charSet="utf-8" />
          <title>Math For CS</title>
          <meta name="description" content="Math For CS Page" />
        </Helmet>
        <div className="sm:sticky top-0 z-50">
          <NavigationBar />
        </div>
        <div className="max-w-screen-2xl mx-auto px-3 sm:px-6 md:px-8">
          <EnjoyMathematicsHeader />

          <div className="mt-6">
            <nav className="flex tracking-wider text-center text-sm sm:text-base lg:text-lg">
              <Link
                to="/blog/"
                className="z-30 flex-1 mr-0 sm:mr-2 border-l border-r border-white px-2 py-3 leading-5 bg-new-green hover:bg-green-50 hover:text-gray-900 text-white shadow rounded "
              >
                Latest
              </Link>
              <Link
                to="/math-for-cs/"
                className="z-30 flex-1 mr-0 sm:mr-2 border-l border-r border-white px-2 py-3 leading-5 text-cool-gray-900 shadow rounded "
              >
                Math
              </Link>
              <Link
                to="/algorithmic-puzzles/"
                className="z-30 flex-1 mr-0 sm:mr-2 border-l border-r border-white px-2 py-3 leading-5 bg-new-green hover:bg-green-50 hover:text-gray-900 text-white shadow rounded"
              >
                Puzzles
              </Link>
              <Link
                to="/popular-algorithms/"
                className="z-30 flex-1 mr-0 sm:mr-2 px-2 py-3 leading-5 bg-new-green hover:bg-green-50 hover:text-gray-900 text-white shadow rounded"
              >
                Algorithms
              </Link>
            </nav>
          </div>

          <section>
            <div className="content content mt-2 sm:mt-6">
              <MathForCsRoll />
            </div>
          </section>

          <div className="mt-12">
            <Footer />
          </div>
        </div>
      </React.Fragment>
    )
  }
}
